<template>
  <v-dialog
    v-model="showDialog"
    :persistent="loading || busy || persistent"
    transition="dialog-bottom-transition"
    :width="width"
    :min-width="minWidth"
    :max-width="maxWidth"
  >
    <v-card
      rounded="xl"
      border="0"
    >
      <template v-if="loading">
        <div class="py-6 d-flex justify-center">
          <spinner class="spinner" />
        </div>
      </template>

      <template v-else>
        <!--  top header with buttons  -->
        <v-card-title class="px-4 py-4">
          <div class="d-flex flex-nowrap justify-start align-center">
            <h2 class="text-beton mr-1">
              {{ title }}
            </h2>

            <div class="ml-auto">
              <v-btn
                v-if="closable"
                variant="text"
                density="compact"
                icon="$close"
                :disabled="busy"
                @click="onClose"
              />
            </div>
          </div>

          <div class="d-flex align-start">
            <span
              v-if="subtitle"
              class="text-h4 text-beton mb-2 mr-2"
              style="white-space: normal"
            >
              {{ subtitle }}
            </span>
            <slot name="badge" />
          </div>
        </v-card-title>

        <!--  content in box card  -->
        <v-card-text
          v-if="$slots.default"
          ref="content"
          class="px-4 py-0"
        >
          <slot />
        </v-card-text>

        <v-card-actions
          v-if="$slots.actions"
          class="px-4 py-4"
        >
          <slot name="actions" />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import Spinner from '~/app-modules/core/components/icons/lg-spinner.vue';

defineOptions({
  name: 'XDialog',
});

const {
  title = '',
  subtitle,
  loading = false,
  busy = false,
  closable = true,
  persistent = false,
  width = 'unset',
  minWidth = 'unset',
  maxWidth = 'unset',
} = defineProps<{
  title?: string;
  subtitle?: string;
  busy?: boolean;
  loading?: boolean;
  closable?: boolean;
  persistent?: boolean;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
}>();

const showDialog = defineModel<boolean>({ default: false });

function onClose() {
  showDialog.value = false;
}
</script>

<style scoped lang="scss">
.spinner {
  @include animate-spinner();
}
</style>
